import Utility from "../../../shared/utility.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify";
import userService from "./app-user-service.js";
import commonAPIService from "../../common/js/common-api-service.js";
import { bus } from "../../../main.js";

export default {
  name: "userForm",
  props: {
    pageName: String,
    pemail: String,
    pfirstName: String,
    plastName: String,
    ptheme: String,
    password: String,
    pphone: String,
    prole: String,
    puserId: Number,
    edit: Boolean,
    active: Boolean,
    pShowResetPwdByAdmin: Boolean,
    pShowUnlockUser: Boolean,
    editActive: Boolean,
    locked: Boolean,
  },
  data: () => ({
    nameRule: [(v) => !!v || "Input field is required", (v) => (v || "").length <= 50 || "Max Length of 50 character"],
    selectedRole: [],
    newpassword: "",
    confirmpassword: "",
    isPasswordNewShow: false,
    isPasswordConShow: false,
    selectionRule: [(v) => !!v || "Input field is required"],
    passwordRules: [
      (v) => {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
        return (
          pattern.test(v) ||
          "Password must contain - at least 1 lowercase alphabetical character, at least 1 uppercase alphabetical character, at least 1 numeric character, at least one special character, must be eight characters or longer"
        );
      },
    ],
    isFormValid: false,
    passwordDialog: false,
    rules: {
      required: (value) => !!value || "Input field is required.",
      passwordRules: (value) => {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
        return pattern.test(value) || "Password must be valid.";
      },
    },
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      (v) => (v || "").length <= 50 || "Max Length of 50 character",
    ],
    themes: [],
    password: "",
    isPasswordShow: false,
    theme: "",
    firstName: "",
    accessMenu: false,
    phone: "",
    view: true,
    installers: "",
    lastName: "",
    search: "",
    addItems: false,
    roleList: [],
    userId: EncryptUtility.localStorageDecrypt("userID"),
    roleId: 0,
    selected: [],
    menuListData: [],
    index: 0,
    userDeleteId: 0,
    emailDisable: false,
    disable: true,
    pphone: "",
    phoneNumChange: null,
    countriesList: ["IN", "US", "GB", "CA"],
    dark: JSON.parse(EncryptUtility.localStorageDecrypt("theme")),
    phoneCountryCode: "",
    disabled: false,
    roleValidated: true,
    headers: [
      {
        text: "Role Name",
        value: "RoleName",
      },
      {
        text: "Role Description",
        value: "Description",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    headersMenu: [
      {
        text: "Group",
        value: "group",
        align: "start",
      },
      {
        text: "Subgroup",
        value: "subgroup",
      },
      { text: "Pages", value: "pages" },
      {
        text: " Page Actions",
        value: "allowedactions",
      },
    ],
    userDataList: [],
    userStatus: "",
    finalNumber: "",
    number: "",
    countryCode: "",
    phoneNumberList: [],
    countryCodeData: [],
    showWareDetails: false,
    wareItems: [],
    wareShowSelected: true,
    warePushData: [],
    wareSearch: "",
    unlockUserDialog: false,
    wareHeaders: [
      {
        text: "Ware",
        value: "ware",
        align: "start",
      },
    ],
  }),
  computed: {
    //Comparing New password with COnfirm password
    passwordConfirmationRule() {
      return () => this.newpassword === this.confirmpassword || "Passwords must match";
    },
  },
  created() {
    this.getTheme();
    this.getRoleList();
    bus.$on("onClickUserUpdate", (data) => {
      this.onUpdateClicked = data;
      this.onUpdateClicked ? this.editUser() : "";
    });
  },
  async mounted() {
    this.getCountryCodeData();
    //This is hard coded because it a  general component which action to perform is determined by pageName
    if (this.pageName !== "Add User") {
      this.prole.forEach((element) => {
        this.selected.push(element);
      });
      let obj = this.selected.filter((obj) => obj.showWare == true);
      if (obj !== undefined && obj.length !== 0) {
        this.wareDetails();
      }
      this.emailDisable = true;
      if (this.pphone !== undefined) {
        this.finalNumber = this.pphone;
        if (this.finalNumber !== "undefined-") {
          if (this.finalNumber.includes("-")) {
            let countryData = await commonAPIService.countryListNew(this.userId, "", 3, "get");
            this.countryCodeData = countryData.CountryInfo;
            let countryCod = this.finalNumber?.split("-")[0];
            let countryCd = this.countryCodeData.filter((x) => x.CountryPhoneCode == countryCod);
            if (countryCd.length !== 0) {
              this.countryCode = countryCd[0].CountryName + " " + countryCd[0].CountryPhoneCode;
              this.number = this.finalNumber?.split("-")[1];
            }
          } else {
            this.number = this.finalNumber;
          }
        }
      }
    } else if (this.pageName == "Add User") {
      this.pphone = "";
      this.emailDisable = false;
      this.selected = [];
    }
    if (this.pageName == "Add User") {
      //CSS Fixes to removed disabled roles
      let tblChkBxCss = document.getElementById("tblRoles");
      tblChkBxCss.classList.remove("roleChkBox-disable");
      tblChkBxCss.classList.add("roleChkBox-enable");
      this.$refs.userAddForm.resetValidation();
    }
  },
  methods: {
    //to check if its a number of not
    isNumber(event) {
      let value = Utility.isNumber(event);
      return value;
    },
    // TO get country code
    async getCountryCodeData() {
      let countryData = await commonAPIService.countryListNew(this.userId, "", 3, "get");
      this.countryCodeData = countryData.CountryInfo;
      this.phoneNumberList = this.countryCodeData.map((x) => ({
        CountryName: x.CountryName == "Canada" ? x.CountryName + " +1" : x.CountryName + " " + x.CountryPhoneCode,
        CountryPhoneCode: x.CountryName + " " + x.CountryPhoneCode,
      }));
    },
    //To show passwords
    showNewPassword() {
      this.isPasswordNewShow = !this.isPasswordNewShow;
    },
    showConPassword() {
      this.isPasswordConShow = !this.isPasswordConShow;
    },
    //To get Role ID
    editItem(item) {
      this.accessMenu = true;
      this.roleId = item.RoleId;
      this.menuList();
    },
    //To decide which APi to call Add user or Edit User
    pageNamed() {
      //This is hard coded because it a  general component which action to perform is determined by pageName
      this.pageName == "Add User" ? this.addUser() : this.editUser();
    },
    //to show password
    showPassword() {
      this.isPasswordShow = !this.isPasswordShow;
    },
    //Last Name Change
    onChangeLastName() {
      this.theme = "";
      this.getTheme();
    },
    // to get theme list
    getTheme() {
      this.themes = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/co/theme_list?user_id=${this.userId}&install_id'${this.installers}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.themes = responseData;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    // To check if email is valid
    emailCheck(email) {
      if (this.$refs.emailForm?.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let checkemail = email;
        let userId = this.userId;
        this.axios
          .get(`/ap/user_email_validate?email=${checkemail}&UserId=${userId}`)
          .then((response) => {
            if (response.status == 200) {
              this.emailDisable = true;
              this.edit = false;
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    // to Copy Test
    copyToClipBoard(textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    },
    // to auto Generate Password
    autoGenerate() {
      function generatePassword(len, options) {
        const chars = {
          num: "1234567890",
          specialChar: "@$!%*?&",
          lowerCase: "abcdefghijklmnopqrstuvwxyz",
          upperCase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
          custom: options.custom || undefined,
        };
        const shuffleStr = (str) =>
          str
            .split("")
            .sort(() => 0.5 - Math.random())
            .join("");
        const factor = Math.ceil(len / Object.values(options).reduce((a, b) => (b ? a + 1 : a), 0));
        let str = "";
        if (options.numbers) str += shuffleStr(chars.num.repeat(factor)).substr(0, factor);
        if (options.special) str += shuffleStr(chars.specialChar.repeat(factor)).substr(0, factor);
        if (options.lowerCase) str += shuffleStr(chars.lowerCase.repeat(factor)).substr(0, factor);
        if (options.upperCase) str += shuffleStr(chars.upperCase.repeat(factor)).substr(0, factor);
        if (options.custom) str += shuffleStr(chars.custom.repeat(factor)).substr(0, factor);
        return shuffleStr(str).substr(0, len);
      }
      this.password = generatePassword(8, {
        numbers: true,
        special: true,
        lowerCase: true,
        upperCase: true,
      });
    },
    //To get Role List
    getRoleList() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("/ap/role_list?UserId=" + this.userId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.roleList = responseData.filter((x) => x.Status == "Active");
            if (this.pageName !== "Add User") {
              this.prole.forEach((element) => {
                let i = this.roleList.map((item) => item.RoleId).indexOf(element.RoleId);
                this.roleList.splice(i, 1);
                this.roleList.unshift(element);
              });
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //to Reset Form
    cancel() {
      if (this.pageName != "Add User") {
        this.selected = [];
        this.prole.forEach((element) => {
          this.selected.push(element);
        });
        this.getRoleList();
        this.getUserDetails(this.puserId);
        this.$refs.userAddForm.resetValidation();
        this.$refs.emailForm.resetValidation();
      } else {
        this.pfirstName = "";
        this.plastName = "";
        this.pmail = "";
        this.ppassword = "";
        this.password = "";
        this.pphone = "";
        this.ptheme = "";
        this.edit = true;
        this.emailDisable = false;

        this.selected = [];
        this.pemail = "";
        this.$refs?.userAddForm.resetValidation();
        this.$refs?.emailForm.resetValidation();
        this.$refs?.emailForm.reset();
        this.$refs?.userAddForm.reset();
      }
    },
    //To cancel the form
    onClickCancel() {
      this.accessMenu = false;
      this.menuListData = [];
    },
    //To call menu list
    menuList() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/up/user_roles_menu?roleId=${this.roleId}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.body.message != null && response.data.body.message != undefined && response.data.body.message != "") {
              let responseData = JSON.parse(response.data.body.message);
              responseData.forEach((element) => {
                // Check if its level is 0
                // Check if its level is 1
                // To get the group Name
                // Check if its level is 2
                if (element.Level == 2) {
                  // To get the group Name
                  let menudetails = responseData.filter((menu) => menu.MenuId == element.ParentId);
                  // To get the subgroup Name
                  let parentdetails = responseData.filter((menu) => menu.MenuId == menudetails[this.index].ParentId);
                  this.menuListData.push({
                    pages: element.FriendlyName,
                    menuid: element.MenuId,
                    group: parentdetails[this.index].FriendlyName,
                    subgroup: menudetails[this.index].FriendlyName,
                  });
                }
                // Check if its level is 4
                else if (element.Level == 3) {
                  // To get the Pages Name
                  let menudetails = responseData.filter((menu) => menu.MenuId == element.ParentId);
                  // To get the subgroup Name
                  let parentdetails = responseData.filter((menu) => menu.MenuId == menudetails[this.index].ParentId);
                  // To get the group Name
                  let groupdetails = responseData.filter((menu) => menu.MenuId == parentdetails[this.index].ParentId);
                  this.menuListData.push({
                    allowedactions: element.FriendlyName,
                    group: groupdetails[this.index].FriendlyName,
                    menuid: element.MenuId,
                    subgroup: parentdetails[this.index].FriendlyName,
                    pages: menudetails[this.index].FriendlyName,
                    level: element.Level,
                  });
                }
              });
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //To add user
    addUser() {
      if (this.selected.length !== 0) {
        let obj = this.selected.filter((obj) => obj.showWare == true);
        if (obj !== undefined && obj.length !== 0) {
          if (this.wareItems.length == 0) {
            this.roleValidated = true;
          } else if (this.wareSelected !== undefined && this.wareSelected.length !== 0) {
            this.roleValidated = true;
          } else {
            this.roleValidated = false;
          }
        } else {
          this.roleValidated = true;
        }
        if (this.roleValidated) {
          if (this.$refs.userAddForm?.validate()) {
            let LoaderDialog = {
              visible: true,
              title: "Please Wait...",
            };
            this.selectedRole = [];
            this.selected.forEach((element) => {
              this.selectedRole.push(element.RoleId);
            });
            this.countryCode = this.countryCode?.split(" ")[1] ? this.countryCode?.split(" ")[1] : "";
            this.countryCode && this.number ? (this.finalNumber = this.countryCode + "-" + this.number) : (this.finalNumber = "");
            this.warePushData = [];
            if (this.wareSelected !== null && this.wareSelected !== undefined) {
              this.warePushData = this.wareSelected.map((obj) => obj.ware_id);
            } else {
              this.warePushData = [];
            }
            let userData = {
              InstallId: parseInt(EncryptUtility.localStorageDecrypt("installId")),
              Fname: this.pfirstName,
              Lname: this.plastName,
              Email: this.pemail,
              Password: this.password,
              Phone: this.finalNumber,
              ThemeCode: this.ptheme,
              CreatedBy: this.userId,
              RoleIds: this.selectedRole,
              WareIds: this.warePushData,
            };
            //this is because table validation in form is not possible
            if (this.selected.length <= 0) {
              let Alert = {
                type: "error",
                isShow: true,
                message: "Please select at least one of the Role from the list",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              this.axios
                .post("/up/user_create", userData)
                .then((response) => {
                  if (response.status == 200 || response.status == 204) {
                    LoaderDialog.visible = false;
                    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                    let Alert = {
                      type: "success",
                      isShow: true,
                      message: response.data.message,
                    };
                    this.$router.push("/app-user");
                    this.$store.commit("ConfigModule/Alert", Alert);
                  } else {
                    LoaderDialog.visible = false;
                    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                    let Alert = {
                      type: "error",
                      isShow: true,
                      message: response.data.message,
                    };
                    this.$store.commit("ConfigModule/Alert", Alert);
                  }
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                })
                .catch((error) => {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: Utility.apiError(error),
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                  this.errorMessage = error.message;
                });
            }
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Please select a Warehouse for adding this Role",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select any Role to add",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //To update User
    editUser() {
      if (this.selected.length !== 0) {
        let obj = this.selected.filter((obj) => obj.showWare == true);
        if (obj !== undefined && obj.length !== 0) {
          if (this.wareItems.length == 0) {
            this.roleValidated = true;
          } else if (this.wareSelected !== undefined && this.wareSelected.length !== 0) {
            this.roleValidated = true;
          } else {
            this.roleValidated = false;
          }
        } else {
          this.roleValidated = true;
        }
        if (this.roleValidated) {
          if (this.$refs.userAddForm?.validate()) {
            let LoaderDialog = {
              visible: true,
              title: "Please Wait...",
            };
            if (this.active == true) {
              this.userStatus = "Active";
            } else {
              this.userStatus = "Inactive";
            }
            if (this.countryCode !== null && this.countryCode !== undefined) {
              this.countryCode = this.countryCode?.split(" ")[1];
              this.finalNumber = this.countryCode + "-" + this.number;
            }
            this.warePushData = [];
            if (this.wareSelected !== null && this.wareSelected !== undefined) {
              this.warePushData = this.wareSelected.map((obj) => obj.ware_id);
            } else {
              this.warePushData = [];
            }
            let userData = {
              UserId: parseInt(atob(this.$route.params.id)),
              Fname: this.pfirstName,
              Lname: this.plastName,
              Email: this.pemail,
              Phone: this.finalNumber,
              ThemeCode: this.ptheme,
              Status: this.userStatus,
              CreatedBy: parseInt(this.userId),
              WareIds: this.warePushData,
            };
            this.axios
              .post("/ap/user_update", userData)
              .then((response) => {
                if (response.status == 200 || response.status == 204) {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "success",
                    isShow: true,
                    message: response.data.body.message,
                  };
                  this.updateRole();
                  this.$router.push("/app-user");
                  this.$store.commit("ConfigModule/Alert", Alert);
                } else {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: response.data.message,
                  };
                  this.addMenuDialog = false;
                  this.$store.commit("ConfigModule/Alert", Alert);
                }
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              })
              .catch((error) => {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: Utility.apiError(error),
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.errorMessage = error.message;
              });
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Please select a Warehouse for adding this Role",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select any Role to update",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //To update userRole
    updateRole() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.selectedRole = [];
      this.selected.forEach((element) => {
        this.selectedRole.push(element.RoleId);
      });
      let userData = {
        UserId: parseInt(atob(this.$route.params.id)),
        RoleIds: this.selectedRole,
        CreatedBy: this.userId,
      };

      this.axios
        .post("/up/user_role_update", userData)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Country code selection
    onUpdate() {
      if (this.pphone !== undefined && this.pphone !== null) {
        this.phoneNumChange = this.pphone.replace(/-|\s/g, "");
      }
    },
    //TO get the user Details with Respect to User ID
    getUserDetails(puserId) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/up/user_details_roles?userId=${puserId}&ExecUserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.userDataList = responseData;
            this.pemail = this.userDataList[0].Email;
            this.pfirstName = this.userDataList[0].FName;
            this.plastName = this.userDataList[0].LName;
            this.pphone = this.userDataList[0].Phone;
            this.ptheme = this.userDataList[0].ThemeCode;
            this.prole = this.userDataList[0].RoleInfo;
            this.locked = this.userDataList[0].Locked;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Close Dialog popup
    closeDialog() {
      if (this.accessMenu) {
        this.accessMenu = false;
        this.menuListData = [];
      }
      if (this.passwordDialog) {
        this.newpassword = "";
        this.confirmpassword = "";
        this.passwordDialog = false;
        this.$refs.passwordResetForm.resetValidation();
      }
      if (this.unlockUserDialog) {
        this.unlockUserDialog = false;
        this.getUserDetails(this.puserId);
      }
    },
    //Reset Password by Admin
    passwordReset() {
      if (!this.$refs.passwordResetForm?.validate()) return false;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let passwordModel = {
        UserId: this.puserId,
        NewPassword: this.confirmpassword,
        ActionUserId: this.userId,
      };
      this.axios
        .post("ap/user_pwdreset_admin", passwordModel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.$refs.passwordResetForm.reset();
            this.passwordDialog = false;
            this.newpassword = "";
            this.confirmpassword = "";
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Warehouse Details for the CUST Roles
    async wareDetails() {
      let obj = {
        currentUser_key: parseInt(this.puserId == null ? 0 : this.puserId),
        lstRoleIds: this.selected[0].RoleId,
        UserId: parseInt(this.userId),
      };
      let wareData = await userService.getRoleWareList("post", obj);
      if (wareData.message !== "NA") {
        this.wareShowSelected = true;
        this.wareItems = [];
        this.wareItems = wareData;
        if (this.pageName !== "Add User") {
          if (this.prole[0].RoleId == this.selected[0].RoleId) {
            let wareselItems = this.wareItems.filter((item) => item.isActive == true);
            if (wareselItems !== undefined) {
              if (wareselItems.length !== 0) {
                this.wareSelected = wareselItems;
              } else {
                this.wareSelected = [];
              }
            } else {
              this.wareSelected = [];
            }
          } else {
            this.wareSelected = [];
          }
        } else {
          this.wareSelected = [];
        }
        this.showWareDetails = true;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No warehouses found for this Role",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.wareShowSelected = true;
        this.wareItems = [];
        this.showWareDetails = true;
        this.wareSelected = [];
      }
    },
    //Checkbox in the datatable
    roleDetails(d) {
      let roleExist = this.selected.find((element) => element.RoleId == d.RoleId);
      if (roleExist == undefined) {
        let obj = this.selected.filter((obj) => obj.showWare == true);
        if (d.showWare) {
          if (obj.length == 1) {
            this.selected = [];
            this.selected.push(d);
            this.wareDetails();
          } else if (obj.length == 0) {
            this.selected = [];
            this.selected.push(d);
            this.wareDetails();
          }
        } else if (!d.showWare) {
          if (obj.length == 1) {
            this.selected = [];
            this.selected.push(d);
            this.showWareDetails = false;
            this.wareSelected = [];
          } else if (obj.length == 0) {
            this.selected.push(d);
            this.showWareDetails = false;
            this.wareSelected = [];
          }
        }
      } else {
        if (this.selected.length == 1 || this.selected.length == 0) {
          this.selected = [];
        } else {
          this.selected = this.selected.filter((obj) => obj.RoleId !== roleExist.RoleId);
        }
      }
    },
    //Unlock user
    unlockUser() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.axios
        .get("up/user_unlock?UserId=" + this.puserId + "&ActionUserId=" + this.userId)
        .then((response) => {
          if (response.status == 200) {
            this.closeDialog();
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
  },
  components: {
    VueTelInputVuetify,
  },
};
